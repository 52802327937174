import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NAVBAR_HEIGHT } from "../utils/constants";
import useScrollPosition from "../hooks/useScrollPosition";
import { useEffect, useState } from "react";
import { Logo, navbarLinks } from "../utils/content";
import LinkButton from "./Buttons/LinkButton";
import LanguageIcon from "@mui/icons-material/Language";
import LaunchButton from "./Buttons/LaunchButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useThemeContext } from "../utils/theme/ThemeContextProvider";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

const Navbar = () => {
  const [isDark, setIsDark] = useState(true);
  const { mode, toggleColorMode } = useThemeContext();
  const scrollPosition = useScrollPosition();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    console.log(theme.palette.mode);
    setIsDark(theme.palette.mode === "dark" ? true : false);
  }, [theme.palette.mode]);
  return (
    <AppBar
      elevation={0}
      sx={{
        py: 1,
        height: NAVBAR_HEIGHT,
        bgcolor: scrollPosition > 10 ? "rgba(0, 0, 0, 0.2)" : "transparent",
        backdropFilter: scrollPosition > 10 ? "blur(60px)" : "blur(0px)",
      }}
    >
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {/* LOGO */}
          <img
            src={!isDark ? Logo.logoBlack : Logo.logoWhite}
            style={{ height: "80%", width: "50px", objectFit: "contain" }}
            alt="LOGO"
          />

          {/* Links */}
          {!isMobile && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={6}
              sx={{ flex: 1 }}
              flexWrap="wrap"
            >
              {navbarLinks.map((value) => {
                return (
                  <LinkButton key={value.title}>
                    <Typography variant="body2">{value.title}</Typography>
                    {value.icon}
                  </LinkButton>
                );
              })}
            </Stack>
          )}

          {/* Action Button */}
          {isMobile ? (
            <IconButton>
              <MenuIcon />
            </IconButton>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <LinkButton>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={toggleColorMode}
                  color="inherit"
                >
                  {mode === "dark" ? (
                    <LightModeIcon fontSize="small" />
                  ) : (
                    <DarkModeIcon fontSize="small" />
                  )}
                </IconButton>
              </LinkButton>
              <LinkButton>
                <LanguageIcon fontSize="small" />
                <Typography variant="body2">EN</Typography>
              </LinkButton>
              <LaunchButton />
            </Stack>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default Navbar;
