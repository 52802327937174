import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { whereWeDistributeRowOne } from "../utils/content";
import "./marquee.css";

const Partners = () => {
  return (
    <Box>
      <Paper sx={{ p: 4 }}>
        <Container>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              variant="h3"
              alignItems="center"
              justifyContent="center"
              align="center"
            >
              Unlocking Digital Global Music Distribution
            </Typography>
            <Typography
              variant="h6"
              alignItems="center"
              justifyContent="center"
              align="center"
              mt={1}
            >
              Reach Global Audiences Effortlessly with Our Digital Music
              Distribution Platform
            </Typography>
          </Stack>
        </Container>
        <Container sx={{ pt: 4 }} className="marquee-container">
          <Marquee speed={40} pauseOnHover>
            {whereWeDistributeRowOne.map((item) => {
              return (
                <Paper
                  key={item.title}
                  elevation={2}
                  sx={{ p: 2, m: 1, background: "paper" }}
                >
                  <img src={item.logo} alt={item.title} height="60px" />
                </Paper>
              );
            })}
          </Marquee>
        </Container>
        <Container sx={{ pt: 1 }} className="marquee-container">
          <Marquee direction="right" speed={40} pauseOnHover>
            {whereWeDistributeRowOne.map((item) => {
              return (
                <Paper
                  key={item.title}
                  elevation={2}
                  sx={{ p: 2, m: 1, background: "paper" }}
                >
                  <img src={item.logo} alt={item.title} height="60px" />
                </Paper>
              );
            })}
          </Marquee>
        </Container>
      </Paper>
    </Box>
  );
};

export default Partners;
