import { Stack } from "@mui/material";
import React from "react";

interface LinkButtonProps {
  children: React.ReactNode;
}

const LinkButton: React.FC<LinkButtonProps> = ({ children, ...props }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.2}
      sx={{
        cursor: "pointer",
        color: "text.secondary",
        "&:hover": { color: "text.primary" },
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default LinkButton;
