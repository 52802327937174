import LogoBlack from "../assets/icon/logo-black.png";
import LogoWhite from "../assets/icon/white-white.png";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CallMadeIcon from "@mui/icons-material/CallMade";
import AceTemp from "../assets/ace.webp";

export const Logo = {
  logoBlack: LogoBlack,
  logoWhite: LogoWhite,
};

export const aceArtwork = AceTemp;

export const navbarLinks = [
  {
    title: "Music",
    // icon: <KeyboardArrowDownIcon fontSize="small" />,
  },
  {
    title: "Videos",
    // icon: <KeyboardArrowDownIcon fontSize="small" />,
  },
  {
    title: "Events",
    // icon: <KeyboardArrowDownIcon fontSize="small" />,
  },
  {
    title: "Merch",
    // icon: <KeyboardArrowDownIcon fontSize="small" />,
  },
  {
    title: "Artists",
    // icon: <KeyboardArrowDownIcon fontSize="small" />,
  },
  {
    title: "Studios",
    // icon: <KeyboardArrowDownIcon fontSize="small" />,
  },
  // {
  //   title: "EEG",
  //   icon: <KeyboardArrowDownIcon fontSize="small" />,
  // },
  // {
  //   title: "Innovation",
  //   icon: <KeyboardArrowDownIcon fontSize="small" />,
  // },
  // {
  //   title: "Distribution",
  //   icon: <KeyboardArrowDownIcon fontSize="small" />,
  // },
  // {
  //   title: "Careers",
  //   icon: <KeyboardArrowDownIcon fontSize="small" />,
  // },
  // {
  //   title: "About",
  //   icon: <KeyboardArrowDownIcon fontSize="small" />,
  // },
  // {
  //   title: "Contact",
  //   icon: <KeyboardArrowDownIcon fontSize="small" />,
  // },
  {
    title: "Blog",
    icon: <CallMadeIcon sx={{ fontSize: 12 }} />,
    spacing: 0.5,
  },
];

export const whereWeDistributeRowOne = [
  {
    title: "spotify",
    logo: LogoBlack,
  },
  {
    title: "spotify1",
    logo: LogoBlack,
  },
  {
    title: "spotify2",
    logo: LogoBlack,
  },
  {
    title: "spotify3",
    logo: LogoBlack,
  },
  {
    title: "spotify4",
    logo: LogoBlack,
  },
  {
    title: "spotify5",
    logo: LogoBlack,
  },
  {
    title: "spotify6",
    logo: LogoBlack,
  },
  {
    title: "spotify7",
    logo: LogoBlack,
  },
  {
    title: "spotify8",
    logo: LogoBlack,
  },
  {
    title: "spotify9",
    logo: LogoBlack,
  },
];

export const whereWeDistributeRowTwo = [
  {
    title: "spotify",
    logo: LogoBlack,
  },
  {
    title: "spotify1",
    logo: LogoBlack,
  },
  {
    title: "spotify2",
    logo: LogoBlack,
  },
  {
    title: "spotify3",
    logo: LogoBlack,
  },
  {
    title: "spotify4",
    logo: LogoBlack,
  },
  {
    title: "spotify5",
    logo: LogoBlack,
  },
  {
    title: "spotify6",
    logo: LogoBlack,
  },
  {
    title: "spotify7",
    logo: LogoBlack,
  },
  {
    title: "spotify8",
    logo: LogoBlack,
  },
  {
    title: "spotify9",
    logo: LogoBlack,
  },
];
