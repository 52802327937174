import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import AnimateMusicBackground from "../components/AnimateMusicBackground";
import LaunchButton from "../components/Buttons/LaunchButton";
import { aceArtwork } from "../utils/content";

const HomeLanding = () => {
  return (
    <Box sx={{ height: "100vh" }}>
      {/* background */}
      <AnimateMusicBackground />
      <Container sx={{ height: "100vh" }}>
        <Stack
          sx={{ height: "100%" }}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          {/* content */}
          <Stack>
            <Typography variant="h4">Ace Mambombo</Typography>
            <Typography variant="h6">Khawubuye</Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              spacing={4}
            >
              <LaunchButton sx={{ height: 58, px: 3 }} />
            </Stack>
          </Stack>
          {/* artwork */}
          <Paper sx={{ p: 1 }}>
            <img
              src={aceArtwork}
              alt="artwork"
              style={{ maxWidth: "600px", maxHeight: "600px" }}
            />
          </Paper>
        </Stack>
      </Container>
    </Box>
  );
};

export default HomeLanding;
