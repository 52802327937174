import Navbar from "../components/Navbar";
import Partners from "../components/Partners";
import HomeLanding from "../containers/HomeLanding";

function Home() {
  return (
    <>
      <Navbar />
      <HomeLanding />
      <Partners />
    </>
  );
}

export default Home;
