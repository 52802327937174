// Background.tsx
import React from "react";
import { motion } from "framer-motion";

interface BackgroundProps {
  colors: string[]; // Array of colors for the gradient
}

const Background: React.FC<BackgroundProps> = ({ colors }) => {
  const gradient = `linear-gradient(90deg, ${colors.join(", ")})`;

  return (
    <motion.div
      className="background--custom"
      initial={{ backgroundPosition: 0 }}
      animate={{ backgroundPosition: "100%" }}
      transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }} // Adjusted duration for smoother animation
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundImage: gradient,
        backgroundSize: "400% 400%", // Adjusted size for slower animation
      }}
    />
  );
};

export default Background;
