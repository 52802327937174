import { useEffect, useState } from "react";
import { aceArtwork } from "../utils/content";
import { usePalette } from "color-thief-react";
import CustomBackground from "./GradientBackground";
import { Box } from "@mui/material";

const AnimateMusicBackground = () => {
  const [colors, setColors] = useState<string[]>([]);
  const { data } = usePalette(aceArtwork, 6, "hex");
  // const generateRandomColor = () => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };
  // const randomColors = Array.from({ length: 3 }, generateRandomColor);

  useEffect(() => {
    if (data !== undefined) setColors(data.slice(-4));
  }, [data]);
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: -10,
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {colors.length > 0 && <CustomBackground colors={colors} />}
    </Box>
  );
};

export default AnimateMusicBackground;
