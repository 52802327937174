import { PaletteMode } from "@mui/material";
import { amber, grey } from "@mui/material/colors";
import typography from "./typography";

const theme = {
  palette: {
    primary: amber,
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#d2302a",
          },
          secondary: {
            main: "#000000",
          },
          info: {
            main: "#2d5e90",
          },
          divider: amber[200],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#d2302a",
          },
          secondary: {
            main: "#000000",
          },
          background: {
            default: "#2f0202",
            paper: "#731010",
          },
          text: {
            secondary: "rgba(255, 255, 255, 0.6)",
          },
        }),
    typography,
    shape: {
      borderRadius: 16,
    },
  },
});

export default theme;
